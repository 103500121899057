.whoevacuate-wrap {
    background-image: url('../../img/evacuateWho/evacuate-who-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 1px 0;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    min-height: 800px;
}

.whoevacuate_inner {
    max-width: 506px;
    width: 100%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #F5F6F8;
}

.whoevacuate-title {
    margin-bottom: 14px;
    color: #F5F6F8;
}

.whoevacuate-title-h3 {
    font-weight: 700;
    margin-bottom: 44px;
    color: #F5F6F8;
}

.whoevacuate-content_title {
    font-family: 'e-Ukraine';
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 26px;
}

@media screen and (max-width: 992px) {
    .whoevacuate-wrap {
        min-height: 700px;
    }
}

@media screen and (max-width: 768px) {
    .whoevacuate-wrap {
        background-image: url('../../img/evacuateWho/evacuate-who-bg-mob.jpg');
        background-position: top;
    }

    .whoevacuate-title {
        max-width: 310px;
        margin-bottom: 10px;
    }

    .whoevacuate-title-h3 {
        margin-bottom: 25px;
    }

    .whoevacuate-content_title {
        margin-bottom: 47px;
    }

    .whoevacuate-wrap {
        min-height: 600px;
    }
}