.openMenu {
    position: fixed;
    width: 400px;
    background-color: #0D1821;
    height: 100vh;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.5s;
    overflow: auto;
    z-index: 2;
    padding: 90px 20px 50px;
    z-index: 20;
}

.openMenu-close {
    position: absolute;
    top: 45px;
    right: 25px;
    cursor: pointer;
}

.openMenu ul {
    margin-left: 0;
    width: 100%;
}

.openMenu_li {
    text-align: left;
    font-size: 18px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #6C7187;
}

.openMenu_li a:hover {
    color: #D3F26A;
}

.openMenu_social {
    display: flex;
    margin-top: 46px;
}

.openMenu_social-logo {
    margin-right: 50px;
}

.openMenu_social-fb {
    margin-right: 33px;
}

.menu_inst {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.menu_inst svg {
    width: 44px;
    margin-right: 6px;
}