.about_wrap::after {
    content: '';
    position: absolute;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    background-image: url('../../img/about/round_text.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 200px;
    height: 200px;
}

.about-inner {
    display: flex;
    justify-content: space-between;
}

.about-content {
    margin-right: 100px;
}

.about-block-img {
    width: 400px;
    min-width: 400px;
}

.about-content_parag {
    margin-bottom: 60px;
}

.about-content_parag-desc {
    margin-bottom: 30px;
}

.about-content_parag-desc:last-of-type {
    margin-bottom: 0px;
}

.about-content_parag-title {
    font-family: 'e-Ukraine';
    font-size: 24px;
    margin-bottom: 17px;
}

.about-title-h3 {
    max-width: 545px;
}

.about-title-h3_mob {
    display: none;
}

.about-title-h3 .green {
    font-weight: 400;
}

@media screen and (max-width: 1400px) {
    .about-content {
        margin-right: 50px;
    }

    .about-block-img {
        width: 370px;
        min-width: 370px;
    }
}

@media screen and (max-width: 1200px) {
    .about-block-img {
        width: 300px;
        min-width: 300px;
    }

    .about-title-h3_pc {
        display: none;
    }

    .about-title-h3_mob {
        display: block;
        max-width: unset;
    }
}

@media screen and (max-width: 768px) {
    .about-inner {
        flex-direction: column;
    }

    .about-block-img {
        margin: 0 auto 47px;
    }

    .about-content_parag {
        margin-bottom: 80px;
    }

    .about-content_parag:last-of-type {
        margin-bottom: 50px;
    }

    .about-content_parag-title {
        margin-bottom: 30px;
    }

    .about-content {
        margin-right: 0;
    }

    .about_wrap::after {
        content: unset;
    }
}