.evacuatefrom-wrap {
    padding: 1px 0;
    background: #D3F26A;
}

.evacuatefrom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.evacuatefrom-img-block {
    width: 635px;
    min-width: 635px;
    margin-left: 24px;
}

.evacuatefrom-desc {
    margin-bottom: 22px;
}

.evacuatefrom-desc:last-of-type {
    margin-bottom: 54px;
}

.evacuatefrom-img-block_mob {
    display: none;
}

.evacuatefrom-content {
    width: 100%;
}

@media screen and (max-width: 1400px) {
    .evacuatefrom-img-block {
        width: 515px;
        min-width: 515px;
    }
}

@media screen and (max-width: 1200px) {
    .evacuatefrom-img-block_mob {
        display: block;
        margin: 0 auto;
        position: relative;
    }

    .evacuatefrom-img-block_mob::after {
        content: '';
        position: absolute;
        right: 0;
        top: -10px;
        background-image: url('../../img/EvacuateFrom/slogan.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 119px;
        height: 90px;
    }

    .evacuatefrom-img-block_pc {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .evacuatefrom-img-block_mob {
        transform: translateX(-40%) scale(1.4);
        margin: 110px auto;
    }
}

@media screen and (max-width: 576px) {
    .evacuatefrom-img-block_mob {
        transform: translateX(-40%) scale(1.2);
        margin: 60px auto;
    }

    .evacuatefrom-img-block_mob::after {
        right: 0;
    }
}

@media screen and (max-width: 400px) {
    .evacuatefrom-img-block_mob::after {
        right: 30px;
        width: 99px;
        height: 80px;
        top: 0px;
    }

    .evacuatefrom-img-block_mob {
        transform: translateX(-42%) scale(1.1);
    }
}