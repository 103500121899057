@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap');

@font-face {
  font-family: "e-Ukraine";
  src: url("./fonts/e-Ukraine-Light.otf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "e-Ukraine";
  src: url("./fonts/e-Ukraine-Light.otf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "e-Ukraine";
  src: url("./fonts/e-Ukraine-Medium.otf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "e-Ukraine";
  src: url("./fonts/e-Ukraine-Bold.otf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  word-break: break-word;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
}

body {
  position: relative;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: #fff;
  color: #0D1821;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

img {
  max-width: 100%;
  border-style: none;
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

svg {
  display: block;
  width: 100%;
}

ul li,
ol li {
  list-style-type: none;
}

label {
  cursor: pointer;
}

button {
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

iframe {
  border: none;
  outline: none;
}

input,
textarea {
  padding: 5px 0;
  background: transparent;
  font-size: 16px;
  color: #6C7187;
  line-height: 20px;
  outline: none;
  border: none;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid rgba(108, 113, 135, 0.5);
  width: 100%;
}

textarea {
  min-height: 80px;
  resize: none;
}

input.invalid,
textarea.invalid {
  border-color: #E26D5A;
  color: #E26D5A;
}

input:focus,
textarea:focus {
  outline: none;
}

input::placeholder,
textarea::placeholder {
  color: #6C7187;
  font-size: 16px;
}

input.fill {
  border-color: #6BB378;
}

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #6BB378;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 18px;
  }
}