.saved_wrap {
    padding: 1px 0;
    background-color: #6BB378;
}

.saved {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.saved_title {
    color: #F5F6F8;
    width: fit-content;
}

.saved_slider {
    width: 60%;
}

.saved_item {
    border-radius: 24px;
    overflow: hidden;
}

.saved_item::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(37, 40, 43, 0.2), rgba(37, 40, 43, 0.2));
}

.slide.selected {
    transform: scale(1.3);
    z-index: 10;
    transition: .4s;
}

.slide.selected .saved_item::after {
    background: transparent;
}

.saved_slider {
    height: 450px;
}

.saved_slider .slider-wrapper.axis-horizontal {
    height: 450px;
    margin-bottom: 50px;
}

.saved_slider ul {
    height: 450px;
    align-items: center;
}

.saved_slider .control-arrow::before {
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
    width: 88px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.saved_slider .control-arrow.control-prev::before {
    background-image: url('../../img/saved/Arrow-left.svg');
}

.saved_slider .control-arrow.control-next::before {
    background-image: url('../../img/saved/Arrow-right.svg');
}

.saved_slider .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
}

.saved_slider .carousel.carousel-slider .control-arrow:pressed {
    background: transparent;
}

.saved_slider .carousel.carousel-slider .control-arrow {
    top: unset;
    bottom: 20px;
    padding: 0;
    opacity: 1;
}

.saved_slider .carousel .carousel-status {
    top: unset;
    right: unset;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'e-Ukraine';
    font-weight: 700;
    font-size: 34px;
    color: #F5F6F8;
    text-shadow: none;
    padding: 0;
    transition: unset;
}

.saved_slider--mob {
    display: none;
}

@media screen and (max-width: 1200px) {
    .saved {
        flex-direction: column;
        align-items: flex-start;
    }

    .saved_slider {
        width: 84%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 992px) {
    .saved_slider {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .saved_slider {
        height: 300px;
    }

    .saved_slider .slider-wrapper.axis-horizontal {
        height: 300px;
    }

    .saved_slider ul {
        height: 300px;
    }
}

@media screen and (max-width: 576px) {
    .saved_slider .control-arrow.control-prev {
        display: none;
    }

    .saved_slider .carousel .carousel-status {
        left: 11vw;
        font-size: 34px;
    }

    .saved_slider .carousel.carousel-slider .control-arrow {
        bottom: 5px;
    }

    .container.saved {
        padding: 0;
    }

    .saved_slider--mob {
        display: block;
    }

    .saved_slider--pc {
        display: none;
    }

    .saved_slider {
        height: 640px;
    }

    .saved_slider .slider-wrapper.axis-horizontal {
        height: 640px;
    }

    .saved_slider ul {
        height: 640px;
    }

    .saved_title {
        margin-left: 20px;
    }
}

@media screen and (max-width: 480px) {
    .saved_slider {
        height: 480px;
    }

    .saved_slider .slider-wrapper.axis-horizontal {
        height: 480px;
    }

    .saved_slider ul {
        height: 480px;
    }
}

@media screen and (max-width: 380px) {
    .saved_slider {
        height: 440px;
    }

    .saved_slider .slider-wrapper.axis-horizontal {
        height: 440px;
    }

    .saved_slider ul {
        height: 440px;
    }
}

@media screen and (max-width: 380px) {
    .saved_slider {
        height: 410px;
    }

    .saved_slider .slider-wrapper.axis-horizontal {
        height: 410px;
    }

    .saved_slider ul {
        height: 410px;
    }
}