.faq-wrap::after {
    content: '';
    position: absolute;
    bottom: -230px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    background-image: url('../../img/about/round_text.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 174px;
    height: 174px;
    z-index: 1;
}

details>summary {
    list-style: none;
}

summary::-webkit-details-marker {
    display: none
}

.faq-item--title {
    position: relative;
    font-weight: 500;
    font-size: 18px;
}

.faq-item--title::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scaleY(-1);
    right: 0;
    background-image: url('../../img/triangle.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 12px;
    width: 12px;
}

details[open] .faq-item--title::after {
    transform: translateY(-50%) scaleY(1);
}

.faq-item {
    border-bottom: 1px solid #0D1821;
    padding-bottom: 15px;
    margin-bottom: 30px;
    cursor: pointer;
}

.faq-item:last-of-type {
    margin-bottom: 0;
}

.faq-item--list {
    margin-top: 10px;
    font-size: 16px;
}

.faq-item--list_item {
    margin-bottom: 8px;
    list-style-type: disc;
    margin-left: 20px;
}

.container.faq {
    max-width: 895px;
}

.faq-title {
    text-align: center;
}

@media screen and (max-width: 992px) {
    .container.faq {
        max-width: 680px;
    }
}

@media screen and (max-width: 768px) {
    .container.faq {
        max-width: 500px;
        margin-bottom: 200px;
    }

    .faq-wrap::after {
        bottom: -291px;
    }

    .faq-item {
        margin-bottom: 40px;
    }

    .faq-title {
        text-align: left;
    }
}

@media screen and (max-width: 576px) {
    .container.faq {
        max-width: 100%;
    }
}