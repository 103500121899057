.donate-content {
    width: 55%;
}

.donate-title {
    position: relative;
    width: fit-content;
    display: initial;
}

.donate-title::after {
    content: '';
    position: absolute;
    bottom: -150px;
    right: -150px;
    background-image: url('../../img/donate/valute-img.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 154px;
    height: 180px;
    transform: rotate(5deg);
}

.donate-content_desc {
    margin-bottom: 23px;
    max-width: 580px;
}

.donate-content_desc:last-of-type {
    margin-bottom: 65px;
}

@media screen and (max-width: 1400px) {
    .donate-content {
        width: 100%;
    }

    .donate-title {
        max-width: 620px;
        width: 100%;
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .donate-title {
        width: fit-content;
        display: initial;
    }

    .donate-title::after {
        bottom: -140px;
        right: -180px;
    }

    .donate-content_desc:first-of-type {
        margin-top: 40px;
        max-width: 290px;
    }
}

@media screen and (max-width: 576px) {
    .donate-title {
        max-width: 300px;
        width: 100%;
        display: block;
    }

    .donate-title::after {
        bottom: -130px;
        right: -110px;
        width: 144px;
        height: 160px;
    }
}

@media screen and (max-width: 420px) {
    .donate-title::after {
        bottom: -110px;
        right: -50px;
        transform: rotate(-16deg);
    }

    .donate-content_desc:first-of-type {
        max-width: 260px;
    }
}

@media screen and (max-width: 370px) {
    .donate-title::after {
        bottom: -90px;
        right: -20px;
        width: 114px;
        height: 133px;
    }
}