.ourhistory-wrap {
    background-image: url("../../img/ourHistory/history-bg.jpg");
    height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1px 0;
    color: #F5F6F8;
}

.ourhistory {
    position: absolute;
    inset: 0;
    margin-bottom: 0;
}

.ourhistory-title {
    color: #F5F6F8;
}

.ourhistory-outer {
    width: 100%;
    overflow: scroll;
    height: 100%;
}

.ourhistory-inner {
    width: 50%;
    margin-left: auto;
}

.ourhistory-outer::-webkit-scrollbar {
    display: none;
}

.ourhistory-item {
    padding-top: 25px;
    margin-bottom: 45px;
}

.ourhistory-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 7px;
    width: 161px;
    background: #D3F26A;
}

.ourhistory-date {
    font-family: 'e-Ukraine';
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 14px;
}

@media screen and (max-width: 1400px) {
    .ourhistory-inner {
        width: 60%;
    }
}

@media screen and (max-width: 1200px) {
    .ourhistory-inner {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .ourhistory-wrap {
        background-attachment: unset;
        height: 100%;
        background-position: -200px center;
    }

    .ourhistory-outer {
        overflow: visible;
    }

    .ourhistory {
        position: relative;
    }

    .ourhistory-item {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 15px;
        padding-left: 25px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ourhistory-date {
        font-size: 18px;
        margin-bottom: 14px;
    }

    .ourhistory-desc {
        font-size: 16px;
    }

    .ourhistory-item::before {
        width: 7px;
        height: 140px;
    }
}

@media screen and (max-width: 400px) {
    .ourhistory-date {
        font-size: 16px;
    }

    .ourhistory-desc {
        font-size: 14px;
    }
}