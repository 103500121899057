.main_wrap {
    background-image: url('../../img/main/bg-img.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
}

.main_block::before {
    content: '';
    position: absolute;
    top: -50%;
    left: 56%;
    background-image: url('../../img/main/slogan.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 242px;
    height: 188px;
}

.main_title {
    margin-bottom: 50px;
    text-transform: uppercase;
}

.main_undertitle {
    font-weight: 400;
    font-size: 28px;
    line-height: 121%;
    font-family: 'e-Ukraine';
    color: #F5F6F8;
    margin-bottom: 28px;
}

.main_block button {
    color: #0D1821;
    background: #fff;
}

@media screen and (min-width: 1600px) and (max-height: 800px) {
    .main_wrap {
        height: 100% !important;
        padding-top: 80px;
    }

    .main_block::before {
        top: -30%;
    }
}

@media screen and (max-width: 1800px) {
    .main_wrap {
        max-height: 1200px;
    }
}

@media screen and (max-width: 1800px) and (max-height: 1000px) {
    .main_block::before {
        left: 48%;
        top: -30%;
    }
}

@media screen and (max-width: 1600px) {
    .main_block::before {
        left: 46%;
    }
}

@media screen and (max-width: 1600px) and (min-width: 1401px) and (max-height: 800px) {
    .main_wrap {
        height: 100% !important;
        padding-top: 80px;
    }
}

@media screen and (max-width: 1400px) {
    .main_wrap {
        max-height: 900px;
    }

    .main_block::before {
        left: 46%;
        top: -30%;
    }
}

@media screen and (max-width: 1400px) and (min-width: 1201px) and (max-height: 850px) {
    .main_block::before {
        width: 202px;
        height: 158px;
    }
}

@media screen and (max-width: 1400px) and (min-width: 1201px) and (max-height: 700px) {
    .main_wrap {
        height: 100% !important;
        padding-top: 60px;
    }
}

@media screen and (max-width: 1200px) {
    .main_block::before {
        left: 54%;
        width: 177px;
        height: 138px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 993px) and (max-height: 900px) {
    .main_block::before {
        width: 172px;
        height: 133px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 993px) and (max-height: 750px) {
    .main_block::before {
        width: 152px;
        height: 118px;
        left: 50%;
        top: -60px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 993px) and (max-height: 650px) {
    .main_wrap {
        height: 100% !important;
    }
}

@media screen and (max-width: 992px) {
    .main_wrap {
        max-height: 700px;
    }

    .main_block::before {
        left: 68%;
        top: 322px;
        width: 172px;
        height: 135px;
    }
}

@media screen and (max-width: 992px) and (min-width: 769px) and (max-height: 640px) {
    .main_block::before {
        top: 280px;
    }
}

@media screen and (max-width: 992px) and (min-width: 769px) and (max-height: 540px) {
    .main_wrap {
        height: 100% !important;
    }

    .main_block::before {
        left: 49%;
        top: 140px;
        width: 143px;
        height: 111px;
    }

    .main_undertitle {
        max-width: 240px;
    }

    .container.main_block {
        margin: 100px auto;
    }
}

@media screen and (max-width: 768px) {
    .main_wrap {
        background-image: url('../../img/main/bg-img-mob.jpg');
        max-height: 1000px;
        align-items: flex-end;
        background-position-y: -260px;
        height: 100% !important;
        padding-top: 270px;
    }

    .main_title {
        max-width: 366px;
        width: 100%;
    }

    .main_undertitle {
        text-align: right;
        max-width: 250px;
        margin-left: auto;
    }

    .main_block button {
        color: #D3F26A;
        background: #0D1821;
    }

    .main_wrap::before {
        content: '';
        position: absolute;
        top: 11%;
        left: 20%;
        background-image: url('../../img/main/slogan.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 178px;
        height: 139px;
    }

    .main_block::before {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .main_wrap::before {
        left: 20px;
    }

    .main_wrap {
        background-position-y: -200px;
        padding-top: 210px;
    }
}

@media screen and (max-width: 485px) {
    .main_wrap {
        background-position-y: -70px;
    }
}

@media screen and (max-width: 420px) {
    h1 {
        font-size: 35px;
    }

    .main_wrap::before {
        width: 138px;
        height: 107px;
    }

    .main_wrap {
        padding-top: 140px;
        background-position-y: 0px;
    }

    .main_title {
        max-width: 245px;
    }
}