ul.menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

ul.menu li {
    text-align: center;
}

.menu_pc {
    transition: .4s;
    margin: 0 24px;
    position: relative;
    transition: 1s ease-in;
    z-index: 2;
}

ul.menu li a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    width: fit-content;
}

.menu_pc--without_ml {
    margin-left: 0;
}

.menu_pc a:hover {
    color: #D3F26A;
}

.mobile-menu {
    margin-left: auto;
}

.mob_blur_bcg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -20%);
    background: rgba(13, 24, 33, .4);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    width: 200vw;
    height: 270vw;
    cursor: pointer;
    z-index: 10;
}

.burger-menu {
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .menu_pc {
        display: none;
    }

    ul.menu {
        width: 100%;
        left: 50%;
        top: 0px;
    }

    .header_wrap--scroll ul.menu {
        padding-top: 0
    }

    .header_logo {
        display: none;
    }

    .logo-mob {
        max-width: 139px;
        width: 100%;
        z-index: 5;
    }

    .logo-mob.active {
        display: none;
    }

    .header_wrap--scroll .header_container {
        padding-top: 0
    }

    .header_container {
        margin-top: 0;
    }

    ul.menu li.menu_pc--without_before a {
        padding-top: 0;
    }
}

@media screen and (max-width: 992px) {
    header {
        height: 70px;
    }
}

@media screen and (max-width: 768px) {
    .header_wrap.header_wrap--scroll {
        padding: 5px 0;
    }
}

@media screen and (max-width: 450px) {
    .openMenu {
        width: 100%;
    }
}