.howworks-wrap {
    padding: 1px 0;
    background: #6C7187;
    color: #F5F6F8;
}

.howworks-wrap::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -2px;
    background-image: url('../../img/howItWorks/women-with-child.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 390px;
    height: 725px;
    z-index: 0;
}

.howworks {
    z-index: 1;
}

.howworks-title {
    color: #D3F26A;
}

.howworks-items {
    display: flex;
    margin-bottom: 40px;
    margin-top: 130px;
}

.howworks-items::before {
    content: '';
    position: absolute;
    top: -82px;
    left: 0;
    background-image: url('../../img/howItWorks/stages.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 741px;
    height: 43px;
}

.howworks-item_header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.howworks-item_num {
    text-shadow:
        -0 -1px 0 #D3F26A,
        0 -1px 0 #D3F26A,
        -0 1px 0 #D3F26A,
        0 1px 0 #D3F26A,
        -1px -0 0 #D3F26A,
        1px -0 0 #D3F26A,
        -1px 0 0 #D3F26A,
        1px 0 0 #D3F26A,
        -1px -1px 0 #D3F26A,
        1px -1px 0 #D3F26A,
        -1px 1px 0 #D3F26A,
        1px 1px 0 #D3F26A,
        -1px -1px 0 #D3F26A,
        1px -1px 0 #D3F26A,
        -1px 1px 0 #D3F26A,
        1px 1px 0 #D3F26A;
    font-size: 56px;
    font-weight: 700;
    color: #6C7187;
    margin-right: 20px;
    font-family: 'e-Ukraine';
}

.howworks-item_title {
    font-family: 'e-Ukraine';
    font-size: 18px;
    font-weight: 500;
}

.howworks-item {
    max-width: 330px;
    width: 100%;
    margin-right: 28px;
}

.howworks-item:last-child {
    margin-right: 0;
}

@media screen and (max-width: 1600px) {
    .howworks-item {
        margin-right: 20px;
    }

    .howworks-wrap::after {
        width: 310px;
        height: 578px;
    }

    .howworks-items::before {
        width: 730px;
    }
}

@media screen and (max-width: 1400px) {
    .howworks-wrap::after {
        width: 240px;
        height: 447px;
        transform: scaleX(-1);
        left: 0;
        right: unset;
    }

    .howworks-items {
        margin-bottom: 170px;
    }

    .howworks-items::before {
        width: 708px;
    }
}

@media screen and (max-width: 1200px) {
    .howworks-items::before {
        width: 588px;
        height: 33px;
        top: -63px;
    }

    .howworks-items {
        margin-top: 100px;
        margin-bottom: 140px;
    }
}

@media screen and (max-width: 992px) {
    .howworks {
        font-size: 18px;
    }

    .howworks-items::before {
        width: 465px;
        height: 28px;
        top: -58px;
    }
}

@media screen and (max-width: 768px) {
    .howworks-items {
        flex-direction: column;
        margin-left: 100px;
        margin-top: 0;
        margin-bottom: 295px;
    }

    .howworks-items::before {
        background-image: url('../../img/howItWorks/stages-mob.svg');
        width: 30px;
        height: 541px;
        top: 16px;
        left: -100px;
    }

    .howworks-item {
        margin-right: 0px;
        margin-bottom: 52px;
    }

    .howworks-item:last-child {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 422px) {
    .howworks-items {
        margin-left: 60px;
    }

    .howworks-items::before {
        left: -60px;
    }
}

@media screen and (max-width: 389px) {
    .howworks-items::before {
        width: 37px;
        height: 599px;
    }
}

@media screen and (max-width: 367px) {
    .howworks-items::before {
        height: 618px;
    }
}