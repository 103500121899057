@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap);
@font-face {
  font-family: "e-Ukraine";
  src: url(../../static/media/e-Ukraine-Light.051230ee.otf) format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "e-Ukraine";
  src: url(../../static/media/e-Ukraine-Light.051230ee.otf) format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "e-Ukraine";
  src: url(../../static/media/e-Ukraine-Medium.768e3644.otf) format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "e-Ukraine";
  src: url(../../static/media/e-Ukraine-Bold.b0b74cb7.otf) format("truetype");
  font-style: normal;
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  word-break: break-word;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
}

body {
  position: relative;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: #fff;
  color: #0D1821;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

img {
  max-width: 100%;
  border-style: none;
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

svg {
  display: block;
  width: 100%;
}

ul li,
ol li {
  list-style-type: none;
}

label {
  cursor: pointer;
}

button {
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

iframe {
  border: none;
  outline: none;
}

input,
textarea {
  padding: 5px 0;
  background: transparent;
  font-size: 16px;
  color: #6C7187;
  line-height: 20px;
  outline: none;
  border: none;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid rgba(108, 113, 135, 0.5);
  width: 100%;
}

textarea {
  min-height: 80px;
  resize: none;
}

input.invalid,
textarea.invalid {
  border-color: #E26D5A;
  color: #E26D5A;
}

input:focus,
textarea:focus {
  outline: none;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #6C7187;
  font-size: 16px;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #6C7187;
  font-size: 16px;
}

input::placeholder,
textarea::placeholder {
  color: #6C7187;
  font-size: 16px;
}

input.fill {
  border-color: #6BB378;
}

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #6BB378;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 18px;
  }
}
div {
    position: relative;
}

.container {
    max-width: 1400px;
    margin: 140px auto;
    padding: 0 100px;
    width: 100%;
}

.container--full {
    margin: 140px auto;
}

h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 1.4;
    color: #D3F26A;
    margin: 0;
    z-index: 5;
    font-family: 'e-Ukraine', sans-serif;
}

h2 {
    text-align: left;
    font-weight: 700;
    font-size: 54px;
    line-height: 1.2;
    color: #0D1821;
    margin: 0;
    margin-bottom: 40px;
    font-family: 'e-Ukraine', sans-serif;
    text-transform: uppercase;
}

h3 {
    font-weight: 500;
    font-size: 34px;
    color: #0D1821;
    text-transform: uppercase;
    font-style: italic;
}

h4 {
    font-weight: 400;
    font-size: 26px;
    color: rgba(57, 34, 109, .8);
    font-family: 'e-Ukraine', sans-serif;
}

a.button,
.button {
    display: block;
    align-items: center;
    justify-content: center;
    width: 294px;
    padding: 23px 10px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    color: #D3F26A;
    background: #0D1821;
    transition: .4s;
    font-family: 'e-Ukraine', sans-serif;
    text-transform: uppercase;
}

a.button:hover,
.button:hover {
    background: #0D1821;
    box-shadow: 0px 0px 20px rgba(13, 24, 33, 0.38);
    color: #D3F26A;
}

a.button:pressed,
.button:pressed {
    background: #0D1821;
    color: #fff;
}

a.button.second_btn,
button.second_btn {
    display: block;
    align-items: center;
    justify-content: center;
    width: 294px;
    padding: 23px 10px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    color: #0D1821;
    background: #D3F26A;
    transition: .4s;
    font-family: 'e-Ukraine', sans-serif;
    text-transform: uppercase;
}

a.button.second_btn:hover,
button.second_btn:hover {
    box-shadow: 0px 0px 20px rgba(13, 24, 33, 0.38);
}

a.button.second_btn:pressed,
button.second_btn:pressed {
    box-shadow: inset 0px 0px 20px rgba(13, 24, 33, 0.1);
}

.green {
    color: #6BB378;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
}

.grey {
    padding: 1px 0;
    background: #F5F6F8;
}

iframe {
    border: none;
}

main {
    overflow-x: hidden;
}

.logo_item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

@media screen and (max-width: 1400px) {
    .container {
        max-width: 1100px;
        padding: 0 50px;
    }

    h1 {
        font-size: 44px;
    }
}

@media screen and (max-width: 1200px) {
    .container {
        max-width: 920px;
    }
}

@media screen and (max-width: 992px) {
    .container {
        max-width: 680px;
        padding: 0 20px;
    }
}

@media screen and (max-width: 768px) {
    .container {
        max-width: 500px;
        margin: 100px auto;
    }

    a.button,
    .button {
        width: 100%;
    }

    a.button.second_btn,
    button.second_btn {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .container {
        max-width: 100%;
    }

    h2 {
        font-size: 35px;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 640px) {
    .logo_item {
        width: calc(90% / 3);
    }
}

@media screen and (max-width: 440px) {
    .logo_item {
        width: calc(90% / 2);
    }
}
.header_wrap {
    background: linear-gradient(180deg, #000000 0%, rgba(13, 24, 33, 0) 100%);
    display: block;
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: fixed;
    top: 0;
    padding: 20px 0;
    z-index: 10000000;
}

header {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    z-index: 555;
    width: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 122px;
    margin: 0 auto 0;
}

.header_container {
    max-width: 1400px;
    padding: 0 100px;
    position: relative;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    margin: 0 auto;
}

.header_wrap.header_wrap--scroll {
    background: rgba(0, 0, 0, .4);
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    padding: 0;
}

.header_wrap.header_wrap--scroll .header_container {
    height: 100px;
}

@media screen and (max-width: 1400px) {
    .header_container {
        max-width: 1100px;
        padding: 0 50px;
    }
}

@media screen and (max-width: 1200px) {
    .header_container {
        max-width: 920px;
    }

    .header_wrap.header_wrap--scroll ul.menu {
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .header_container {
        max-width: 680px;
        padding: 0 20px;
    }
}

@media screen and (max-width: 768px) {
    .header_container {
        max-width: 500px;
    }

    .header_wrap.header_wrap--scroll .header_container {
        height: 50px;
    }
}

@media screen and (max-width: 576px) {
    .header_container {
        max-width: 100%;
    }
}
.openMenu {
    position: fixed;
    width: 400px;
    background-color: #0D1821;
    height: 100vh;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.5s;
    overflow: auto;
    z-index: 2;
    padding: 90px 20px 50px;
    z-index: 20;
}

.openMenu-close {
    position: absolute;
    top: 45px;
    right: 25px;
    cursor: pointer;
}

.openMenu ul {
    margin-left: 0;
    width: 100%;
}

.openMenu_li {
    text-align: left;
    font-size: 18px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #6C7187;
}

.openMenu_li a:hover {
    color: #D3F26A;
}

.openMenu_social {
    display: flex;
    margin-top: 46px;
}

.openMenu_social-logo {
    margin-right: 50px;
}

.openMenu_social-fb {
    margin-right: 33px;
}

.menu_inst {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.menu_inst svg {
    width: 44px;
    margin-right: 6px;
}
ul.menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

ul.menu li {
    text-align: center;
}

.menu_pc {
    transition: .4s;
    margin: 0 24px;
    position: relative;
    transition: 1s ease-in;
    z-index: 2;
}

ul.menu li a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.menu_pc--without_ml {
    margin-left: 0;
}

.menu_pc a:hover {
    color: #D3F26A;
}

.mobile-menu {
    margin-left: auto;
}

.mob_blur_bcg {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -20%);
            transform: translate(-50%, -20%);
    background: rgba(13, 24, 33, .4);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    width: 200vw;
    height: 270vw;
    cursor: pointer;
    z-index: 10;
}

.burger-menu {
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .menu_pc {
        display: none;
    }

    ul.menu {
        width: 100%;
        left: 50%;
        top: 0px;
    }

    .header_wrap--scroll ul.menu {
        padding-top: 0
    }

    .header_logo {
        display: none;
    }

    .logo-mob {
        max-width: 139px;
        width: 100%;
        z-index: 5;
    }

    .logo-mob.active {
        display: none;
    }

    .header_wrap--scroll .header_container {
        padding-top: 0
    }

    .header_container {
        margin-top: 0;
    }

    ul.menu li.menu_pc--without_before a {
        padding-top: 0;
    }
}

@media screen and (max-width: 992px) {
    header {
        height: 70px;
    }
}

@media screen and (max-width: 768px) {
    .header_wrap.header_wrap--scroll {
        padding: 5px 0;
    }
}

@media screen and (max-width: 450px) {
    .openMenu {
        width: 100%;
    }
}
.main_wrap {
    background-image: url(../../static/media/bg-img.046db7b4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
}

.main_block::before {
    content: '';
    position: absolute;
    top: -50%;
    left: 56%;
    background-image: url(../../static/media/slogan.32407b3a.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 242px;
    height: 188px;
}

.main_title {
    margin-bottom: 50px;
    text-transform: uppercase;
}

.main_undertitle {
    font-weight: 400;
    font-size: 28px;
    line-height: 121%;
    font-family: 'e-Ukraine';
    color: #F5F6F8;
    margin-bottom: 28px;
}

.main_block button {
    color: #0D1821;
    background: #fff;
}

@media screen and (min-width: 1600px) and (max-height: 800px) {
    .main_wrap {
        height: 100% !important;
        padding-top: 80px;
    }

    .main_block::before {
        top: -30%;
    }
}

@media screen and (max-width: 1800px) {
    .main_wrap {
        max-height: 1200px;
    }
}

@media screen and (max-width: 1800px) and (max-height: 1000px) {
    .main_block::before {
        left: 48%;
        top: -30%;
    }
}

@media screen and (max-width: 1600px) {
    .main_block::before {
        left: 46%;
    }
}

@media screen and (max-width: 1600px) and (min-width: 1401px) and (max-height: 800px) {
    .main_wrap {
        height: 100% !important;
        padding-top: 80px;
    }
}

@media screen and (max-width: 1400px) {
    .main_wrap {
        max-height: 900px;
    }

    .main_block::before {
        left: 46%;
        top: -30%;
    }
}

@media screen and (max-width: 1400px) and (min-width: 1201px) and (max-height: 850px) {
    .main_block::before {
        width: 202px;
        height: 158px;
    }
}

@media screen and (max-width: 1400px) and (min-width: 1201px) and (max-height: 700px) {
    .main_wrap {
        height: 100% !important;
        padding-top: 60px;
    }
}

@media screen and (max-width: 1200px) {
    .main_block::before {
        left: 54%;
        width: 177px;
        height: 138px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 993px) and (max-height: 900px) {
    .main_block::before {
        width: 172px;
        height: 133px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 993px) and (max-height: 750px) {
    .main_block::before {
        width: 152px;
        height: 118px;
        left: 50%;
        top: -60px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 993px) and (max-height: 650px) {
    .main_wrap {
        height: 100% !important;
    }
}

@media screen and (max-width: 992px) {
    .main_wrap {
        max-height: 700px;
    }

    .main_block::before {
        left: 68%;
        top: 322px;
        width: 172px;
        height: 135px;
    }
}

@media screen and (max-width: 992px) and (min-width: 769px) and (max-height: 640px) {
    .main_block::before {
        top: 280px;
    }
}

@media screen and (max-width: 992px) and (min-width: 769px) and (max-height: 540px) {
    .main_wrap {
        height: 100% !important;
    }

    .main_block::before {
        left: 49%;
        top: 140px;
        width: 143px;
        height: 111px;
    }

    .main_undertitle {
        max-width: 240px;
    }

    .container.main_block {
        margin: 100px auto;
    }
}

@media screen and (max-width: 768px) {
    .main_wrap {
        background-image: url(../../static/media/bg-img-mob.5008e491.jpg);
        max-height: 1000px;
        align-items: flex-end;
        background-position-y: -260px;
        height: 100% !important;
        padding-top: 270px;
    }

    .main_title {
        max-width: 366px;
        width: 100%;
    }

    .main_undertitle {
        text-align: right;
        max-width: 250px;
        margin-left: auto;
    }

    .main_block button {
        color: #D3F26A;
        background: #0D1821;
    }

    .main_wrap::before {
        content: '';
        position: absolute;
        top: 11%;
        left: 20%;
        background-image: url(../../static/media/slogan.32407b3a.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 178px;
        height: 139px;
    }

    .main_block::before {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .main_wrap::before {
        left: 20px;
    }

    .main_wrap {
        background-position-y: -200px;
        padding-top: 210px;
    }
}

@media screen and (max-width: 485px) {
    .main_wrap {
        background-position-y: -70px;
    }
}

@media screen and (max-width: 420px) {
    h1 {
        font-size: 35px;
    }

    .main_wrap::before {
        width: 138px;
        height: 107px;
    }

    .main_wrap {
        padding-top: 140px;
        background-position-y: 0px;
    }

    .main_title {
        max-width: 245px;
    }
}

.popup_desc.popup_desc--sponsor {
    width: auto;
}

.form_message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.numbers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-areas:
        "title numbers"
        "title-h3 numbers"
        "button numbers";
}

.numbers-content {
    grid-area: numbers;
    margin-top: auto;
}

.numbers-title {
    grid-area: title;
}

.numbers-title-h3 {
    grid-area: title-h3;
    margin-bottom: 34px;
    margin-right: 20px;
}

.numbers .button {
    grid-area: button;
    height: 64px;
}

.numbers-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #0D1821;
    margin-bottom: 38px;
}

.numbers-item:last-of-type {
    margin-bottom: 0;
}

.numbers-who {
    font-family: 'e-Ukraine';
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;
    color: #0D1821;
}

.numbers-number {
    font-family: 'e-Ukraine';
    font-weight: 700;
    font-size: 56px;
    text-align: right;
    text-transform: uppercase;
    color: #6BB378;
}

@media screen and (max-width: 1200px) {
    .numbers-title {
        max-width: 500px;
        width: 500px;
    }
}

@media screen and (max-width: 992px) {
    .numbers {
        display: flex;
        flex-direction: column;
    }

    .numbers-content {
        margin-bottom: 60px;
    }

    .numbers-title-h3 {
        margin-right: 0px;
    }

    .numbers-title {
        max-width: 500px;
        width: auto;
    }

    .numbers-number {
        font-size: 46px;
    }
}
.whoevacuate-wrap {
    background-image: url(../../static/media/evacuate-who-bg.4bbec7b3.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 1px 0;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    min-height: 800px;
}

.whoevacuate_inner {
    max-width: 506px;
    width: 100%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #F5F6F8;
}

.whoevacuate-title {
    margin-bottom: 14px;
    color: #F5F6F8;
}

.whoevacuate-title-h3 {
    font-weight: 700;
    margin-bottom: 44px;
    color: #F5F6F8;
}

.whoevacuate-content_title {
    font-family: 'e-Ukraine';
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 26px;
}

@media screen and (max-width: 992px) {
    .whoevacuate-wrap {
        min-height: 700px;
    }
}

@media screen and (max-width: 768px) {
    .whoevacuate-wrap {
        background-image: url(../../static/media/evacuate-who-bg-mob.384a3f2c.jpg);
        background-position: top;
    }

    .whoevacuate-title {
        max-width: 310px;
        margin-bottom: 10px;
    }

    .whoevacuate-title-h3 {
        margin-bottom: 25px;
    }

    .whoevacuate-content_title {
        margin-bottom: 47px;
    }

    .whoevacuate-wrap {
        min-height: 600px;
    }
}
.howworks-wrap {
    padding: 1px 0;
    background: #6C7187;
    color: #F5F6F8;
}

.howworks-wrap::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -2px;
    background-image: url(../../static/media/women-with-child.216439f1.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 390px;
    height: 725px;
    z-index: 0;
}

.howworks {
    z-index: 1;
}

.howworks-title {
    color: #D3F26A;
}

.howworks-items {
    display: flex;
    margin-bottom: 40px;
    margin-top: 130px;
}

.howworks-items::before {
    content: '';
    position: absolute;
    top: -82px;
    left: 0;
    background-image: url(../../static/media/stages.6a8d4ba3.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 741px;
    height: 43px;
}

.howworks-item_header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.howworks-item_num {
    text-shadow:
        -0 -1px 0 #D3F26A,
        0 -1px 0 #D3F26A,
        -0 1px 0 #D3F26A,
        0 1px 0 #D3F26A,
        -1px -0 0 #D3F26A,
        1px -0 0 #D3F26A,
        -1px 0 0 #D3F26A,
        1px 0 0 #D3F26A,
        -1px -1px 0 #D3F26A,
        1px -1px 0 #D3F26A,
        -1px 1px 0 #D3F26A,
        1px 1px 0 #D3F26A,
        -1px -1px 0 #D3F26A,
        1px -1px 0 #D3F26A,
        -1px 1px 0 #D3F26A,
        1px 1px 0 #D3F26A;
    font-size: 56px;
    font-weight: 700;
    color: #6C7187;
    margin-right: 20px;
    font-family: 'e-Ukraine';
}

.howworks-item_title {
    font-family: 'e-Ukraine';
    font-size: 18px;
    font-weight: 500;
}

.howworks-item {
    max-width: 330px;
    width: 100%;
    margin-right: 28px;
}

.howworks-item:last-child {
    margin-right: 0;
}

@media screen and (max-width: 1600px) {
    .howworks-item {
        margin-right: 20px;
    }

    .howworks-wrap::after {
        width: 310px;
        height: 578px;
    }

    .howworks-items::before {
        width: 730px;
    }
}

@media screen and (max-width: 1400px) {
    .howworks-wrap::after {
        width: 240px;
        height: 447px;
        -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
        left: 0;
        right: unset;
    }

    .howworks-items {
        margin-bottom: 170px;
    }

    .howworks-items::before {
        width: 708px;
    }
}

@media screen and (max-width: 1200px) {
    .howworks-items::before {
        width: 588px;
        height: 33px;
        top: -63px;
    }

    .howworks-items {
        margin-top: 100px;
        margin-bottom: 140px;
    }
}

@media screen and (max-width: 992px) {
    .howworks {
        font-size: 18px;
    }

    .howworks-items::before {
        width: 465px;
        height: 28px;
        top: -58px;
    }
}

@media screen and (max-width: 768px) {
    .howworks-items {
        flex-direction: column;
        margin-left: 100px;
        margin-top: 0;
        margin-bottom: 295px;
    }

    .howworks-items::before {
        background-image: url(../../static/media/stages-mob.75d46cfb.svg);
        width: 30px;
        height: 541px;
        top: 16px;
        left: -100px;
    }

    .howworks-item {
        margin-right: 0px;
        margin-bottom: 52px;
    }

    .howworks-item:last-child {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 422px) {
    .howworks-items {
        margin-left: 60px;
    }

    .howworks-items::before {
        left: -60px;
    }
}

@media screen and (max-width: 389px) {
    .howworks-items::before {
        width: 37px;
        height: 599px;
    }
}

@media screen and (max-width: 367px) {
    .howworks-items::before {
        height: 618px;
    }
}
.form_btn[disabled] {
    opacity: .6;
}
.popup-overlay {
    background: rgba(13, 24, 33, .6);
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    z-index: 999999999 !important;
}

.popup_title {
    text-transform: none;
    font-weight: 400;
    font-size: 24px;
    color: #0D1821;
    margin-bottom: 21px;
}

.popup_desc {
    font-size: 18px;
    width: 320px;
    margin-bottom: 25px;
}

.popup-content {
    width: 580px;
    height: auto;
    max-height: calc(100vh - 150px);
    padding: 30px;
    background: #F5F6F8;
    overflow-y: scroll;
}

.popup-content .close {
    position: absolute;
    right: 0;
    top: -30px;
    z-index: 10;
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.popup_title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 15px;
    line-height: 1.2;
}

.popup_abstract {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #8B8B8B;
    margin-bottom: 40px;
}

.popup_video {
    height: 300px !important;
    margin-bottom: 20px;
}

.popup_btn {
    display: block;
    align-items: center;
    justify-content: center;
    width: 294px;
    padding: 23px 10px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    color: #0D1821;
    background: #D3F26A;
    transition: .4s;
    font-family: 'e-Ukraine', sans-serif;
    text-transform: uppercase;
    border-bottom: none;
    margin: 5px auto 0;
}

.reviews_item_wrap .popup_btn {
    font-size: 17px;
    font-weight: 400;
    background: transparent;
    width: auto;
    height: auto;
    color: #61dbfb;
    padding: 0;
    display: inline-block;
}

.reviews_item_wrap .review_btn:hover {
    background: transparent;
    color: #fff;
}

.popup_form {
    display: flex;
    flex-direction: column;
}

.popup_inputs_block {
    display: flex;
    flex-direction: column;
}

.popup_input {
    margin-bottom: 25px;
}

.popup_radio {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 2px 5px;
    margin-right: 10px;
    border: none;
    background-image: url(../../static/media/radiobtn.d2d6fb16.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.popup_radio:checked {
    background-image: url(../../static/media/selected.6420b3e8.svg);
}

.popup_radio_block {
    font-size: 16px;
    color: #6C7187;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.dropdown_city_block {
    font-size: 16px;
    color: #6C7187;
    margin-bottom: 25px;
    width: 100%;
}

.popup_radio_block--inner {
    display: flex;
    align-items: center;
}

.popup_radio_block--title {
    display: block;
    margin-right: 30px;
}

.popup_radio_block--label {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.popup_radio {
    margin-right: 15px;
}

.dropdown_city--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid rgba(108, 113, 135, 0.5);
}

.dropdown_city--header svg {
    width: 18px;
}

.dropdown_city--header svg.active {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
}

.dropdown_city--body {
    border: 1px solid #6C7187;
    border-radius: 4px;
    padding: 16px 20px;
    position: absolute;
    top: 29px;
    left: 0;
    background: #FFFFFF;
    width: 100%;
    height: 171px;
    overflow: auto;
    z-index: 10;
}

.dropdown_city--body::-webkit-scrollbar {
    width: 10px;
}

.dropdown_city--body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px #fff;
    border: solid 2px transparent;
    background: #fff;
    border-radius: 20px;
}

.dropdown_city--body::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px #6BB378;
    border: solid 2px transparent;
    background-color: #ffffff;
    border-radius: 20px;
}

.popup-content::-webkit-scrollbar-track {
    background: #F5F6F8;
    border-radius: 20px;
}

.dropdown_city--item {
    margin-bottom: 16px;
}

.dropdown_city--item:last-of-type {
    margin-bottom: 0;
}

.popup_input.error {
    color: #E26D5A;
    border-color: #E26D5A;
}

.popup_input.error::-webkit-input-placeholder {
    color: #E26D5A;
}

.popup_input.error:-ms-input-placeholder {
    color: #E26D5A;
}

.popup_input.error::placeholder {
    color: #E26D5A;
}

.form_message--title {
    font-family: 'e-Ukraine';
    font-size: 24px;
    color: #6BB378;
    margin-bottom: 20px;
}

.form_message--desc {
    font-size: 18px;
    margin-bottom: 74px;
}

.form_message--slogan {
    width: 195.8px;
    height: 152.55px;
    margin-left: auto;
}

@media screen and (max-width: 992px) {
    .popup-content {
        width: 90%;
        max-height: calc(100vh - 50px);
    }

    .popup_desc {
        width: 100%;
    }

    .popup_btn {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .popup_radio_block {
        flex-direction: column;
        align-items: flex-start;
    }

    .popup_radio_block--title {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .popup_title {
        font-size: 22px;
    }

    .popup_desc {
        font-size: 16px;
    }
}
.saved_wrap {
    padding: 1px 0;
    background-color: #6BB378;
}

.saved {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.saved_title {
    color: #F5F6F8;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.saved_slider {
    width: 60%;
}

.saved_item {
    border-radius: 24px;
    overflow: hidden;
}

.saved_item::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(37, 40, 43, 0.2), rgba(37, 40, 43, 0.2));
}

.slide.selected {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    z-index: 10;
    transition: .4s;
}

.slide.selected .saved_item::after {
    background: transparent;
}

.saved_slider {
    height: 450px;
}

.saved_slider .slider-wrapper.axis-horizontal {
    height: 450px;
    margin-bottom: 50px;
}

.saved_slider ul {
    height: 450px;
    align-items: center;
}

.saved_slider .control-arrow::before {
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
    width: 88px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.saved_slider .control-arrow.control-prev::before {
    background-image: url(../../static/media/Arrow-left.4a45f76f.svg);
}

.saved_slider .control-arrow.control-next::before {
    background-image: url(../../static/media/Arrow-right.1539bf87.svg);
}

.saved_slider .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
}

.saved_slider .carousel.carousel-slider .control-arrow:pressed {
    background: transparent;
}

.saved_slider .carousel.carousel-slider .control-arrow {
    top: unset;
    bottom: 20px;
    padding: 0;
    opacity: 1;
}

.saved_slider .carousel .carousel-status {
    top: unset;
    right: unset;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-family: 'e-Ukraine';
    font-weight: 700;
    font-size: 34px;
    color: #F5F6F8;
    text-shadow: none;
    padding: 0;
    transition: unset;
}

.saved_slider--mob {
    display: none;
}

@media screen and (max-width: 1200px) {
    .saved {
        flex-direction: column;
        align-items: flex-start;
    }

    .saved_slider {
        width: 84%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 992px) {
    .saved_slider {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .saved_slider {
        height: 300px;
    }

    .saved_slider .slider-wrapper.axis-horizontal {
        height: 300px;
    }

    .saved_slider ul {
        height: 300px;
    }
}

@media screen and (max-width: 576px) {
    .saved_slider .control-arrow.control-prev {
        display: none;
    }

    .saved_slider .carousel .carousel-status {
        left: 11vw;
        font-size: 34px;
    }

    .saved_slider .carousel.carousel-slider .control-arrow {
        bottom: 5px;
    }

    .container.saved {
        padding: 0;
    }

    .saved_slider--mob {
        display: block;
    }

    .saved_slider--pc {
        display: none;
    }

    .saved_slider {
        height: 640px;
    }

    .saved_slider .slider-wrapper.axis-horizontal {
        height: 640px;
    }

    .saved_slider ul {
        height: 640px;
    }

    .saved_title {
        margin-left: 20px;
    }
}

@media screen and (max-width: 480px) {
    .saved_slider {
        height: 480px;
    }

    .saved_slider .slider-wrapper.axis-horizontal {
        height: 480px;
    }

    .saved_slider ul {
        height: 480px;
    }
}

@media screen and (max-width: 380px) {
    .saved_slider {
        height: 440px;
    }

    .saved_slider .slider-wrapper.axis-horizontal {
        height: 440px;
    }

    .saved_slider ul {
        height: 440px;
    }
}

@media screen and (max-width: 380px) {
    .saved_slider {
        height: 410px;
    }

    .saved_slider .slider-wrapper.axis-horizontal {
        height: 410px;
    }

    .saved_slider ul {
        height: 410px;
    }
}
.footer-wrap {
    padding: 1px 0;
    background-image: url(../../static/media/footer-bg.22d52b47.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #F5F6F8;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.footer-logo {
    margin-bottom: 63px;
    width: 190px;
    height: auto;
    object-fit: contain;
}

.footer-menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(5, 1fr);
}

.footer-menu_item {
    margin-bottom: 16px;
    margin-right: 45px;
}

.footer-menu_item a:hover {
    color: #D3F26A;
}

.footer-content {
    width: 50%;
}

.footer-data {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer-data_content {
    display: flex;
}

.footer-slogan {
    margin-bottom: 56px;
    width: 292px;
    height: auto;
    object-fit: contain;
}

.footer-data_phone-block {
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 45px;
}

.footer-data_phone-img {
    width: 24px;
    margin-right: 16px;
}

.footer-fb {
    margin-right: 20px;
}

.footer-logo-mob {
    display: none;
}

@media screen and (max-width: 1200px) {
    .footer-menu_item {
        margin-right: 15px;
    }
}

@media screen and (max-width: 992px) {
    .footer-menu {
        display: flex;
        flex-direction: column;
    }

    .footer-data_content {
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .footer-data_phone-block {
        width: 100%;
        margin-right: 0;
        justify-content: flex-end;
        margin-bottom: 20px;
    }

    .footer-slogan {
        width: 240px;
    }
}

@media screen and (max-width: 768px) {
    .footer-wrap {
        background-image: url(../../static/media/footer-bg-mob.c14ccb53.jpg);
    }

    .container.footer {
        margin-top: 200px;
    }

    .footer {
        flex-direction: column;
    }

    .footer-slogan {
        display: none;
    }

    .footer-logo {
        display: none;
    }

    .footer-data_content {
        justify-content: flex-start;
        width: 100%;
    }

    .footer-data_phone-block {
        justify-content: flex-start;
        margin-bottom: 27px;
    }

    .footer-data {
        width: 100%;
    }

    .footer-content {
        width: 100%;
        margin-bottom: 40px;
    }

    .footer-logo-mob {
        display: block;
        width: 195px;
        height: auto;
        object-fit: contain;
        margin-right: 91px;
    }

    .footer::before {
        content: '';
        position: absolute;
        top: -90px;
        right: 20px;
        background-image: url(../../static/media/slogan.32407b3a.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 191px;
        height: 149px;
    }
}

@media screen and (max-width: 576px) {
    .footer-logo-mob {
        margin-right: 40px;
    }
}

@media screen and (max-width: 420px) {

    .footer-fb,
    .footer-tg {
        width: 40px;
        height: 40px;
    }

    .footer-logo-mob {
        width: 165px;
    }
}
.about_wrap::after {
    content: '';
    position: absolute;
    bottom: -100px;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
            transform: translateX(-50%) rotate(45deg);
    background-image: url(../../static/media/round_text.afb3a745.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 200px;
    height: 200px;
}

.about-inner {
    display: flex;
    justify-content: space-between;
}

.about-content {
    margin-right: 100px;
}

.about-block-img {
    width: 400px;
    min-width: 400px;
}

.about-content_parag {
    margin-bottom: 60px;
}

.about-content_parag-desc {
    margin-bottom: 30px;
}

.about-content_parag-desc:last-of-type {
    margin-bottom: 0px;
}

.about-content_parag-title {
    font-family: 'e-Ukraine';
    font-size: 24px;
    margin-bottom: 17px;
}

.about-title-h3 {
    max-width: 545px;
}

.about-title-h3_mob {
    display: none;
}

.about-title-h3 .green {
    font-weight: 400;
}

@media screen and (max-width: 1400px) {
    .about-content {
        margin-right: 50px;
    }

    .about-block-img {
        width: 370px;
        min-width: 370px;
    }
}

@media screen and (max-width: 1200px) {
    .about-block-img {
        width: 300px;
        min-width: 300px;
    }

    .about-title-h3_pc {
        display: none;
    }

    .about-title-h3_mob {
        display: block;
        max-width: unset;
    }
}

@media screen and (max-width: 768px) {
    .about-inner {
        flex-direction: column;
    }

    .about-block-img {
        margin: 0 auto 47px;
    }

    .about-content_parag {
        margin-bottom: 80px;
    }

    .about-content_parag:last-of-type {
        margin-bottom: 50px;
    }

    .about-content_parag-title {
        margin-bottom: 30px;
    }

    .about-content {
        margin-right: 0;
    }

    .about_wrap::after {
        content: unset;
    }
}
.evacuatefrom-wrap {
    padding: 1px 0;
    background: #D3F26A;
}

.evacuatefrom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.evacuatefrom-img-block {
    width: 635px;
    min-width: 635px;
    margin-left: 24px;
}

.evacuatefrom-desc {
    margin-bottom: 22px;
}

.evacuatefrom-desc:last-of-type {
    margin-bottom: 54px;
}

.evacuatefrom-img-block_mob {
    display: none;
}

.evacuatefrom-content {
    width: 100%;
}

@media screen and (max-width: 1400px) {
    .evacuatefrom-img-block {
        width: 515px;
        min-width: 515px;
    }
}

@media screen and (max-width: 1200px) {
    .evacuatefrom-img-block_mob {
        display: block;
        margin: 0 auto;
        position: relative;
    }

    .evacuatefrom-img-block_mob::after {
        content: '';
        position: absolute;
        right: 0;
        top: -10px;
        background-image: url(../../static/media/slogan.6de92f61.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 119px;
        height: 90px;
    }

    .evacuatefrom-img-block_pc {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .evacuatefrom-img-block_mob {
        -webkit-transform: translateX(-40%) scale(1.4);
                transform: translateX(-40%) scale(1.4);
        margin: 110px auto;
    }
}

@media screen and (max-width: 576px) {
    .evacuatefrom-img-block_mob {
        -webkit-transform: translateX(-40%) scale(1.2);
                transform: translateX(-40%) scale(1.2);
        margin: 60px auto;
    }

    .evacuatefrom-img-block_mob::after {
        right: 0;
    }
}

@media screen and (max-width: 400px) {
    .evacuatefrom-img-block_mob::after {
        right: 30px;
        width: 99px;
        height: 80px;
        top: 0px;
    }

    .evacuatefrom-img-block_mob {
        -webkit-transform: translateX(-42%) scale(1.1);
                transform: translateX(-42%) scale(1.1);
    }
}
.evacuatewhere-wrap {
    padding: 1px 0;
    background: #0D1821;
    color: #F5F6F8;
}

.evacuatewhere-wrap::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: url(../../static/media/europemap.e3402abb.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    width: 50%;
    height: 100%;
}

.evacuatewhere_inner {
    max-width: 600px;
}

.evacuatewhere-title {
    color: #F5F6F8;
}

.evacuatewhere-desc {
    margin-bottom: 65px;
}

.evacuatewhere-title-h3 {
    color: #D3F26A;
    font-weight: 700;
    max-width: 360px;
    margin-bottom: 28px;
}

@media screen and (max-width: 992px) {
    .evacuatewhere-wrap::before {
        background-position: right bottom;
        height: 60%;
        right: -30px;
        bottom: unset;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
    }

    .evacuatewhere_inner {
        max-width: 450px;
    }
}

@media screen and (max-width: 768px) {
    .evacuatewhere-wrap::before {
        width: 100%;
        background-position: right center;
        right: 0px;
        top: 48%;
    }

    .evacuatewhere-desc {
        margin-bottom: 345px;
    }
}

@media screen and (max-width: 420px) {
    .evacuatewhere-desc {
        margin-bottom: 300px;
    }

    .evacuatewhere-title-h3 {
        max-width: unset;
    }
}
.donate-content {
    width: 55%;
}

.donate-title {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline;
    display: initial;
}

.donate-title::after {
    content: '';
    position: absolute;
    bottom: -150px;
    right: -150px;
    background-image: url(../../static/media/valute-img.ff71581b.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 154px;
    height: 180px;
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
}

.donate-content_desc {
    margin-bottom: 23px;
    max-width: 580px;
}

.donate-content_desc:last-of-type {
    margin-bottom: 65px;
}

@media screen and (max-width: 1400px) {
    .donate-content {
        width: 100%;
    }

    .donate-title {
        max-width: 620px;
        width: 100%;
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .donate-title {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: inline;
        display: initial;
    }

    .donate-title::after {
        bottom: -140px;
        right: -180px;
    }

    .donate-content_desc:first-of-type {
        margin-top: 40px;
        max-width: 290px;
    }
}

@media screen and (max-width: 576px) {
    .donate-title {
        max-width: 300px;
        width: 100%;
        display: block;
    }

    .donate-title::after {
        bottom: -130px;
        right: -110px;
        width: 144px;
        height: 160px;
    }
}

@media screen and (max-width: 420px) {
    .donate-title::after {
        bottom: -110px;
        right: -50px;
        -webkit-transform: rotate(-16deg);
                transform: rotate(-16deg);
    }

    .donate-content_desc:first-of-type {
        max-width: 260px;
    }
}

@media screen and (max-width: 370px) {
    .donate-title::after {
        bottom: -90px;
        right: -20px;
        width: 114px;
        height: 133px;
    }
}
.ourhistory-wrap {
    background-image: url(../../static/media/history-bg.5d1da290.jpg);
    height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1px 0;
    color: #F5F6F8;
}

.ourhistory {
    position: absolute;
    inset: 0;
    margin-bottom: 0;
}

.ourhistory-title {
    color: #F5F6F8;
}

.ourhistory-outer {
    width: 100%;
    overflow: scroll;
    height: 100%;
}

.ourhistory-inner {
    width: 50%;
    margin-left: auto;
}

.ourhistory-outer::-webkit-scrollbar {
    display: none;
}

.ourhistory-item {
    padding-top: 25px;
    margin-bottom: 45px;
}

.ourhistory-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 7px;
    width: 161px;
    background: #D3F26A;
}

.ourhistory-date {
    font-family: 'e-Ukraine';
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 14px;
}

@media screen and (max-width: 1400px) {
    .ourhistory-inner {
        width: 60%;
    }
}

@media screen and (max-width: 1200px) {
    .ourhistory-inner {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .ourhistory-wrap {
        background-attachment: unset;
        height: 100%;
        background-position: -200px center;
    }

    .ourhistory-outer {
        overflow: visible;
    }

    .ourhistory {
        position: relative;
    }

    .ourhistory-item {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 15px;
        padding-left: 25px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ourhistory-date {
        font-size: 18px;
        margin-bottom: 14px;
    }

    .ourhistory-desc {
        font-size: 16px;
    }

    .ourhistory-item::before {
        width: 7px;
        height: 140px;
    }
}

@media screen and (max-width: 400px) {
    .ourhistory-date {
        font-size: 16px;
    }

    .ourhistory-desc {
        font-size: 14px;
    }
}
.faq-wrap::after {
    content: '';
    position: absolute;
    bottom: -230px;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
            transform: translateX(-50%) rotate(45deg);
    background-image: url(../../static/media/round_text.afb3a745.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 174px;
    height: 174px;
    z-index: 1;
}

details>summary {
    list-style: none;
}

summary::-webkit-details-marker {
    display: none
}

.faq-item--title {
    position: relative;
    font-weight: 500;
    font-size: 18px;
}

.faq-item--title::after {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) scaleY(-1);
            transform: translateY(-50%) scaleY(-1);
    right: 0;
    background-image: url(../../static/media/triangle.303ce8b9.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 12px;
    width: 12px;
}

details[open] .faq-item--title::after {
    -webkit-transform: translateY(-50%) scaleY(1);
            transform: translateY(-50%) scaleY(1);
}

.faq-item {
    border-bottom: 1px solid #0D1821;
    padding-bottom: 15px;
    margin-bottom: 30px;
    cursor: pointer;
}

.faq-item:last-of-type {
    margin-bottom: 0;
}

.faq-item--list {
    margin-top: 10px;
    font-size: 16px;
}

.faq-item--list_item {
    margin-bottom: 8px;
    list-style-type: disc;
    margin-left: 20px;
}

.container.faq {
    max-width: 895px;
}

.faq-title {
    text-align: center;
}

@media screen and (max-width: 992px) {
    .container.faq {
        max-width: 680px;
    }
}

@media screen and (max-width: 768px) {
    .container.faq {
        max-width: 500px;
        margin-bottom: 200px;
    }

    .faq-wrap::after {
        bottom: -291px;
    }

    .faq-item {
        margin-bottom: 40px;
    }

    .faq-title {
        text-align: left;
    }
}

@media screen and (max-width: 576px) {
    .container.faq {
        max-width: 100%;
    }
}
