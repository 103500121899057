.footer-wrap {
    padding: 1px 0;
    background-image: url('../../img/footer/footer-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #F5F6F8;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.footer-logo {
    margin-bottom: 63px;
    width: 190px;
    height: auto;
    object-fit: contain;
}

.footer-menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(5, 1fr);
}

.footer-menu_item {
    margin-bottom: 16px;
    margin-right: 45px;
}

.footer-menu_item a:hover {
    color: #D3F26A;
}

.footer-content {
    width: 50%;
}

.footer-data {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer-data_content {
    display: flex;
}

.footer-slogan {
    margin-bottom: 56px;
    width: 292px;
    height: auto;
    object-fit: contain;
}

.footer-data_phone-block {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-right: 45px;
}

.footer-data_phone-img {
    width: 24px;
    margin-right: 16px;
}

.footer-fb {
    margin-right: 20px;
}

.footer-logo-mob {
    display: none;
}

@media screen and (max-width: 1200px) {
    .footer-menu_item {
        margin-right: 15px;
    }
}

@media screen and (max-width: 992px) {
    .footer-menu {
        display: flex;
        flex-direction: column;
    }

    .footer-data_content {
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .footer-data_phone-block {
        width: 100%;
        margin-right: 0;
        justify-content: flex-end;
        margin-bottom: 20px;
    }

    .footer-slogan {
        width: 240px;
    }
}

@media screen and (max-width: 768px) {
    .footer-wrap {
        background-image: url('../../img/footer/footer-bg-mob.jpg');
    }

    .container.footer {
        margin-top: 200px;
    }

    .footer {
        flex-direction: column;
    }

    .footer-slogan {
        display: none;
    }

    .footer-logo {
        display: none;
    }

    .footer-data_content {
        justify-content: flex-start;
        width: 100%;
    }

    .footer-data_phone-block {
        justify-content: flex-start;
        margin-bottom: 27px;
    }

    .footer-data {
        width: 100%;
    }

    .footer-content {
        width: 100%;
        margin-bottom: 40px;
    }

    .footer-logo-mob {
        display: block;
        width: 195px;
        height: auto;
        object-fit: contain;
        margin-right: 91px;
    }

    .footer::before {
        content: '';
        position: absolute;
        top: -90px;
        right: 20px;
        background-image: url('../../img/main/slogan.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 191px;
        height: 149px;
    }
}

@media screen and (max-width: 576px) {
    .footer-logo-mob {
        margin-right: 40px;
    }
}

@media screen and (max-width: 420px) {

    .footer-fb,
    .footer-tg {
        width: 40px;
        height: 40px;
    }

    .footer-logo-mob {
        width: 165px;
    }
}