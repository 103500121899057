div {
    position: relative;
}

.container {
    max-width: 1400px;
    margin: 140px auto;
    padding: 0 100px;
    width: 100%;
}

.container--full {
    margin: 140px auto;
}

h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 1.4;
    color: #D3F26A;
    margin: 0;
    z-index: 5;
    font-family: 'e-Ukraine', sans-serif;
}

h2 {
    text-align: left;
    font-weight: 700;
    font-size: 54px;
    line-height: 1.2;
    color: #0D1821;
    margin: 0;
    margin-bottom: 40px;
    font-family: 'e-Ukraine', sans-serif;
    text-transform: uppercase;
}

h3 {
    font-weight: 500;
    font-size: 34px;
    color: #0D1821;
    text-transform: uppercase;
    font-style: italic;
}

h4 {
    font-weight: 400;
    font-size: 26px;
    color: rgba(57, 34, 109, .8);
    font-family: 'e-Ukraine', sans-serif;
}

a.button,
.button {
    display: block;
    align-items: center;
    justify-content: center;
    width: 294px;
    padding: 23px 10px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    color: #D3F26A;
    background: #0D1821;
    transition: .4s;
    font-family: 'e-Ukraine', sans-serif;
    text-transform: uppercase;
}

a.button:hover,
.button:hover {
    background: #0D1821;
    box-shadow: 0px 0px 20px rgba(13, 24, 33, 0.38);
    color: #D3F26A;
}

a.button:pressed,
.button:pressed {
    background: #0D1821;
    color: #fff;
}

a.button.second_btn,
button.second_btn {
    display: block;
    align-items: center;
    justify-content: center;
    width: 294px;
    padding: 23px 10px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    color: #0D1821;
    background: #D3F26A;
    transition: .4s;
    font-family: 'e-Ukraine', sans-serif;
    text-transform: uppercase;
}

a.button.second_btn:hover,
button.second_btn:hover {
    box-shadow: 0px 0px 20px rgba(13, 24, 33, 0.38);
}

a.button.second_btn:pressed,
button.second_btn:pressed {
    box-shadow: inset 0px 0px 20px rgba(13, 24, 33, 0.1);
}

.green {
    color: #6BB378;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
}

.grey {
    padding: 1px 0;
    background: #F5F6F8;
}

iframe {
    border: none;
}

main {
    overflow-x: hidden;
}

.logo_item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

@media screen and (max-width: 1400px) {
    .container {
        max-width: 1100px;
        padding: 0 50px;
    }

    h1 {
        font-size: 44px;
    }
}

@media screen and (max-width: 1200px) {
    .container {
        max-width: 920px;
    }
}

@media screen and (max-width: 992px) {
    .container {
        max-width: 680px;
        padding: 0 20px;
    }
}

@media screen and (max-width: 768px) {
    .container {
        max-width: 500px;
        margin: 100px auto;
    }

    a.button,
    .button {
        width: 100%;
    }

    a.button.second_btn,
    button.second_btn {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .container {
        max-width: 100%;
    }

    h2 {
        font-size: 35px;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 640px) {
    .logo_item {
        width: calc(90% / 3);
    }
}

@media screen and (max-width: 440px) {
    .logo_item {
        width: calc(90% / 2);
    }
}