.header_wrap {
    background: linear-gradient(180deg, #000000 0%, rgba(13, 24, 33, 0) 100%);
    display: block;
    width: 100vw;
    height: fit-content;
    position: fixed;
    top: 0;
    padding: 20px 0;
    z-index: 10000000;
}

header {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    z-index: 555;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 122px;
    margin: 0 auto 0;
}

.header_container {
    max-width: 1400px;
    padding: 0 100px;
    position: relative;
    left: unset;
    transform: unset;
    margin: 0 auto;
}

.header_wrap.header_wrap--scroll {
    background: rgba(0, 0, 0, .4);
    backdrop-filter: blur(15px);
    padding: 0;
}

.header_wrap.header_wrap--scroll .header_container {
    height: 100px;
}

@media screen and (max-width: 1400px) {
    .header_container {
        max-width: 1100px;
        padding: 0 50px;
    }
}

@media screen and (max-width: 1200px) {
    .header_container {
        max-width: 920px;
    }

    .header_wrap.header_wrap--scroll ul.menu {
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .header_container {
        max-width: 680px;
        padding: 0 20px;
    }
}

@media screen and (max-width: 768px) {
    .header_container {
        max-width: 500px;
    }

    .header_wrap.header_wrap--scroll .header_container {
        height: 50px;
    }
}

@media screen and (max-width: 576px) {
    .header_container {
        max-width: 100%;
    }
}