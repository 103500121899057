.popup-overlay {
    background: rgba(13, 24, 33, .6);
    backdrop-filter: blur(15px);
    z-index: 999999999 !important;
}

.popup_title {
    text-transform: none;
    font-weight: 400;
    font-size: 24px;
    color: #0D1821;
    margin-bottom: 21px;
}

.popup_desc {
    font-size: 18px;
    width: 320px;
    margin-bottom: 25px;
}

.popup-content {
    width: 580px;
    height: auto;
    max-height: calc(100vh - 150px);
    padding: 30px;
    background: #F5F6F8;
    overflow-y: scroll;
}

.popup-content .close {
    position: absolute;
    right: 0;
    top: -30px;
    z-index: 10;
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.popup_title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 15px;
    line-height: 1.2;
}

.popup_abstract {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #8B8B8B;
    margin-bottom: 40px;
}

.popup_video {
    height: 300px !important;
    margin-bottom: 20px;
}

.popup_btn {
    display: block;
    align-items: center;
    justify-content: center;
    width: 294px;
    padding: 23px 10px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    color: #0D1821;
    background: #D3F26A;
    transition: .4s;
    font-family: 'e-Ukraine', sans-serif;
    text-transform: uppercase;
    border-bottom: none;
    margin: 5px auto 0;
}

.reviews_item_wrap .popup_btn {
    font-size: 17px;
    font-weight: 400;
    background: transparent;
    width: auto;
    height: auto;
    color: #61dbfb;
    padding: 0;
    display: inline-block;
}

.reviews_item_wrap .review_btn:hover {
    background: transparent;
    color: #fff;
}

.popup_form {
    display: flex;
    flex-direction: column;
}

.popup_inputs_block {
    display: flex;
    flex-direction: column;
}

.popup_input {
    margin-bottom: 25px;
}

.popup_radio {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 2px 5px;
    margin-right: 10px;
    border: none;
    background-image: url("../../img/radiobtn.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.popup_radio:checked {
    background-image: url("../../img/selected.svg");
}

.popup_radio_block {
    font-size: 16px;
    color: #6C7187;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.dropdown_city_block {
    font-size: 16px;
    color: #6C7187;
    margin-bottom: 25px;
    width: 100%;
}

.popup_radio_block--inner {
    display: flex;
    align-items: center;
}

.popup_radio_block--title {
    display: block;
    margin-right: 30px;
}

.popup_radio_block--label {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.popup_radio {
    margin-right: 15px;
}

.dropdown_city--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid rgba(108, 113, 135, 0.5);
}

.dropdown_city--header svg {
    width: 18px;
}

.dropdown_city--header svg.active {
    transform: scaleY(-1);
}

.dropdown_city--body {
    border: 1px solid #6C7187;
    border-radius: 4px;
    padding: 16px 20px;
    position: absolute;
    top: 29px;
    left: 0;
    background: #FFFFFF;
    width: 100%;
    height: 171px;
    overflow: auto;
    z-index: 10;
}

.dropdown_city--body::-webkit-scrollbar {
    width: 10px;
}

.dropdown_city--body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px #fff;
    border: solid 2px transparent;
    background: #fff;
    border-radius: 20px;
}

.dropdown_city--body::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px #6BB378;
    border: solid 2px transparent;
    background-color: #ffffff;
    border-radius: 20px;
}

.popup-content::-webkit-scrollbar-track {
    background: #F5F6F8;
    border-radius: 20px;
}

.dropdown_city--item {
    margin-bottom: 16px;
}

.dropdown_city--item:last-of-type {
    margin-bottom: 0;
}

.popup_input.error {
    color: #E26D5A;
    border-color: #E26D5A;
}

.popup_input.error::placeholder {
    color: #E26D5A;
}

.form_message--title {
    font-family: 'e-Ukraine';
    font-size: 24px;
    color: #6BB378;
    margin-bottom: 20px;
}

.form_message--desc {
    font-size: 18px;
    margin-bottom: 74px;
}

.form_message--slogan {
    width: 195.8px;
    height: 152.55px;
    margin-left: auto;
}

@media screen and (max-width: 992px) {
    .popup-content {
        width: 90%;
        max-height: calc(100vh - 50px);
    }

    .popup_desc {
        width: 100%;
    }

    .popup_btn {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .popup_radio_block {
        flex-direction: column;
        align-items: flex-start;
    }

    .popup_radio_block--title {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .popup_title {
        font-size: 22px;
    }

    .popup_desc {
        font-size: 16px;
    }
}