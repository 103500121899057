.evacuatewhere-wrap {
    padding: 1px 0;
    background: #0D1821;
    color: #F5F6F8;
}

.evacuatewhere-wrap::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: url('../../img/EvacuateWhere/europemap.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    width: 50%;
    height: 100%;
}

.evacuatewhere_inner {
    max-width: 600px;
}

.evacuatewhere-title {
    color: #F5F6F8;
}

.evacuatewhere-desc {
    margin-bottom: 65px;
}

.evacuatewhere-title-h3 {
    color: #D3F26A;
    font-weight: 700;
    max-width: 360px;
    margin-bottom: 28px;
}

@media screen and (max-width: 992px) {
    .evacuatewhere-wrap::before {
        background-position: right bottom;
        height: 60%;
        right: -30px;
        bottom: unset;
        top: 50%;
        transform: translateY(-50%);
    }

    .evacuatewhere_inner {
        max-width: 450px;
    }
}

@media screen and (max-width: 768px) {
    .evacuatewhere-wrap::before {
        width: 100%;
        background-position: right center;
        right: 0px;
        top: 48%;
    }

    .evacuatewhere-desc {
        margin-bottom: 345px;
    }
}

@media screen and (max-width: 420px) {
    .evacuatewhere-desc {
        margin-bottom: 300px;
    }

    .evacuatewhere-title-h3 {
        max-width: unset;
    }
}