.numbers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-areas:
        "title numbers"
        "title-h3 numbers"
        "button numbers";
}

.numbers-content {
    grid-area: numbers;
    margin-top: auto;
}

.numbers-title {
    grid-area: title;
}

.numbers-title-h3 {
    grid-area: title-h3;
    margin-bottom: 34px;
    margin-right: 20px;
}

.numbers .button {
    grid-area: button;
    height: 64px;
}

.numbers-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #0D1821;
    margin-bottom: 38px;
}

.numbers-item:last-of-type {
    margin-bottom: 0;
}

.numbers-who {
    font-family: 'e-Ukraine';
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;
    color: #0D1821;
}

.numbers-number {
    font-family: 'e-Ukraine';
    font-weight: 700;
    font-size: 56px;
    text-align: right;
    text-transform: uppercase;
    color: #6BB378;
}

@media screen and (max-width: 1200px) {
    .numbers-title {
        max-width: 500px;
        width: 500px;
    }
}

@media screen and (max-width: 992px) {
    .numbers {
        display: flex;
        flex-direction: column;
    }

    .numbers-content {
        margin-bottom: 60px;
    }

    .numbers-title-h3 {
        margin-right: 0px;
    }

    .numbers-title {
        max-width: 500px;
        width: auto;
    }

    .numbers-number {
        font-size: 46px;
    }
}